<template>
  <div class="about">
    <van-swipe :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="(item,index) in swipeImg" :key="index"><img :src="item" width="100%" height="100%"></van-swipe-item>
    </van-swipe>
    <div class="info">
      <van-row>
        <van-col span="12" class="border-right"><span v-text="count.online"></span><p>在线</p></van-col>
        <van-col span="12"><span v-text="count.total-count.online"></span><p>节能</p></van-col>
      </van-row>
    </div>
    <div class="dev-list">
      <!-- <van-collapse v-model="activeProject" accordion>
        <van-collapse-item v-for="(item,index) in projectList" :title="item.projName+'-'+item.projDetail" :name="item.id" :key="index"> -->
          <div v-if="isLoading"><van-loading class="z-loading" size="24px" vertical>数据努力加载中...</van-loading></div>
          <div v-else>
            <div v-if="devList.length != 0">
                <div class="block" v-for="(item,index) in devList" :key="index" @click="goDetail(item.devCode)">
                  <van-row :class="item.devStatus == 'online' ? 'online' :'offline'">
                      <van-col span="6">
                          <img src="@/assets/pah/img/dev_icon.png" width="60px" height="60px">
                      </van-col>
                      <van-col span="18">
                          <div class="title" v-text="item.devName"></div>
                          <div v-text="item.devRoad || '设备暂未设置地址'"></div>
                      </van-col>
                  </van-row>
                </div>
            </div>
            <div v-else><van-empty description="设备列表为空" /></div>
          </div>
        <!-- </van-collapse-item>
      </van-collapse> -->
        <!-- <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
          <div v-if="!devEmpty">
              <div v-if="devList.length != 0">
                <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" :immediate-check="false">
                    <div class="block" v-for="(item,index) in devList" :key="index" @click="goDetail(item.id)">
                      <van-row :class="item.devStatus == '1' ? 'online' :'offline'">
                          <van-col span="6">
                              <img src="@/assets/dev_icon.png" width="60px" height="60px">
                          </van-col>
                          <van-col span="18">
                              <div class="title" v-text="item.devName"></div>
                              <div v-text="item.devRoad || '设备暂未设置地址'"></div>
                          </van-col>
                      </van-row>
                    </div>
                </van-list>
              </div>
              <div v-else><van-loading class="z-loading" size="24px" vertical>数据努力加载中...</van-loading></div>
          </div>
        </van-pull-refresh> -->
      </div>
    <tabber />
  </div>
</template>

<script>
import tabber from '@/components/pah/tabBar.vue'

export default {
  name: 'devIndex',
  components: {
    tabber
  },
  data(){
    return{
      swipeImg:[
        require('@/assets/pah/img/banner1.jpg'),
        require('@/assets/pah/img/banner2.jpg'),
        require('@/assets/pah/img/banner3.jpg'),
      ],
      count:{
        online:0,
        total:0,
      },
      // loading:false,
      // finished:false,
      // pageSize:10,
      // pageNum:1,
      devList:[],
      // devEmpty:false,
      isLoading: false,
      // activeProject:'',
      projectList:[]
    }
  },
  methods:{
      // 点击设备进入详情页面
      goDetail(devCode){
        if(this.visitor == '1'){
          this.$toast('暂无权限进入设备操控面板');
          return false;
        }
        this.$router.push({path:'/pah/dev/detail',query: {devCode:devCode}})
      },
      // 获取项目
      // porjectList(){
      //   this.$api.PAH.getProject().then( d => {
      //     this.projectList = d
      //     this.activeProject = d[0].id
      //   })
      // },
      // 获取设备
      getDevList(){
        this.isLoading = true;
        this.$api.PAH.getAllDev({
          projectCode:projectCode
        }).then(d => {
          this.devList = d
          this.isLoading = false;
        })
      },
      // 获取分页数据信息
      // onLoad(e){
      //   this.$api.PAH.getDevPage({pageNum:this.pageNum,pageSize:this.pageSize}).then((data)=>{
      //       data.total == '0' ? (this.devEmpty = true,this.$toast('请联系管理员配置设备权限')) : void 0;
      //       this.loading = false;
      //       e =='empty' 
      //       ? (this.devList = data.list,this.$toast('刷新成功')) 
      //       : this.devList = this.devList.concat(data.list);
      //       this.pageNum >= data.pages ? this.finished = true : void 0;
      //       this.pageNum ++;
      //   })
      // },
      // 下拉刷新
      // onRefresh() {
        // this.finished = false;
        // this.pageNum = 1;
        // this.onLoad('empty');
        // setTimeout(() => {
        //   this.isLoading = false;
        // }, 2800);
      // }
  },
  mounted(){
      const projectCode = window.sessionStorage.getItem('projectCode');
      this.visitor = localStorage.getItem('visitor');
      //获取设备列表
      this.isLoading = true;
        this.$api.PAH.getAllDev({
          projectCode:projectCode
        }).then(d => {
          this.devList = d
          this.isLoading = false;
        })
      // this.onLoad();
      // this.porjectList();
      // 获取设备在线数
      this.$api.PAH.getCount({
        projectCode:projectCode
      }).then((data)=>{
          this.count.online = data.online;
          this.count.total = data.total;
      })
  },
  // watch:{
  //   activeProject(val){
  //     val.trim() ? this.getDevList(val) : void 0
  //   }
  // }
}
</script>
<style lang="scss" scoped>
.info{
    padding: 4%;
    background: rgb(69, 167, 129);
    color: white;
    margin-top: -2%;
    .border-right{border-right:1px solid #f0f0f0}
    span{font-size: 1.5rem;font-weight: bold;}
    p{margin: 0;font-size: .9rem;}
}
.dev-list{
    margin-bottom: 22%;
    .block{
        border: 1px solid #f5f5f5;
        background-color: white;
        padding: 4%;
        margin-bottom: 10px;
        font-size: .8rem;
        text-align: left;
        box-shadow: 0 2px 4px #e4e4e4;
        .online{color:#2F9E73;}
        .offline{color:#5d5e5f;}
        img{display: block;margin: 0 auto;}
        .title{
            font-weight: bold;
            line-height: 2.5;
        }
    }
}
</style>